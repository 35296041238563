import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import "../dist/css/style.css"
import inmod3 from "../images/inmod3.png"
const Innovated = () => {
  return (
    <Layout pageName="Innovated">
      <SEO title="Innovated" />
      <Container className="pb-5">
            <div className="text-center">
              <img src={inmod3} className="innovatedImage" />
              <div className="pt-5 mx-auto max-80 max-sm-100">
                <p className="fi-txt">
                  InnovatED is Teach For India’s national platform for
                  incubating entrepreneurs looking to build impactful
                  organizations in education.
                </p>
                <p className="fi-txt">
                  We understand that the first few years of building an
                  organization are challenging – be it creating a value
                  proposition, raising funds or hiring talent. While Teach For
                  India Fellows come with two years of experience working at the
                  frontlines of the problem - starting an organization requires
                  taking on new roles, developing new capabilities and taking on
                  new challenges. InnovatED is Teach For India’s initiative to
                  support early-stage education entrepreneurs from Teach For
                  India looking to take their innovations from the project or
                  prototype stage to a sustainable organization.
                </p>
                <p className="fi-txt">
                  InnovatED is a nine-month incubation platform that provides
                  the following support offerings (including a monthly stipend)
                  to early-stage education entrepreneurs.
                </p>
              </div>
              <div className="text-center py-5">
                <a
                  href="https://medium.com/innovated-insights"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="butn butn-main"
                >
                  Know more
                </a>
              </div>
              <iframe
                width="100%"
                id="youtubeplayermob"
                className="d-block d-lg-none"
                frameBorder="0"
                src="https://www.youtube-nocookie.com/embed/KnpH6qeB0V4"
                title="Teach For India"
              ></iframe>
              <iframe
                width="100%"
                id="youtubeplayer"
                className="d-none d-lg-block"
                frameBorder="0"
                src="https://www.youtube-nocookie.com/embed/KnpH6qeB0V4"
                title="Teach For India"
              ></iframe>
            </div>
            <p className="fi-txt pt-3 pb-5 mx-auto max-80 max-sm-100">
              InnovatED’s long-term vision is to create an interconnected
              community of education entrepreneurs across India - who will learn
              from and support each other during and beyond the incubation
              journey, and create a new narrative for education in the country.
            </p>
            <p className="fi-head mb-0 text-center pt-4 pb-3">
              What our entrepreneurs say
            </p>
            <div className="borderBottomBlack text-center">
              <p className="ed-title max-80 mx-auto bolder px-0">
                “Every single organization that’s been here - there is a clear
                growth in the kind of organization that is being run. I think
                InnovatED has really pushed us to do that. The kind of learning
                curve we’ve had - it’s across all the companies that are here.”
              </p>
              <p className="fi-txt mt-3 mb-4">
                Sahithya Anumolu - Teach For India Fellow, 2014 Co-Founder,
                Inqui-Lab Foundation
              </p>
            </div>
            <div className="borderBottomBlack text-center">
              <p className="ed-title max-80 mx-auto bolder px-0">
                “InnovatED acted as a catalyst and a connector for my project -
                Samarthya. A catalyst because the energy and work of our fellow
                entrepreneurs showed us what’s possible. A connector because we
                learnt from and were put in touch with experts in the social
                sector.”
              </p>
              <p className="fi-txt mt-3 mb-4">
                Sahil Babbar - Teach For India Fellow, 2015 and Co-Founder,
                Samarthya
              </p>
            </div>
          </Container>
    </Layout>
  )
}

export default Innovated
